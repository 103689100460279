import React, { lazy, Suspense } from 'react';
import styled from 'styled-components';
import { HashRouter as Router, Routes, Route } from 'react-router-dom'; 


import GlobalStyles from './globalStyles';
import Navbar from './components/Navbar';
import Footer from './components/Footer';
import LoadingSpinner from './components/LoadingSpinner';
import { colors } from './styles/colors';
import "./App.css";

import "./fonts/Mukta-Bold.ttf";

// Importer les composants
const Hero = lazy(() => import('./components/Hero'));
const AboutMe = lazy(() => import('./components/AboutMe'));
const Works = lazy(() => import('./components/Works'));
const Skills = lazy(() => import('./components/Skills'));


const AppContainer = styled.div`
  font-family: "Light";
  background-color: ${colors.background};
  color: ${colors.text};
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`;

const ContentWrapper = styled.div`
  flex-grow: 1;
  padding-top: 80px; 
`;


function App() {
  return (
    <Router>
      <AppContainer id="top">
        <GlobalStyles />
        <Navbar />
        <ContentWrapper>
          <Suspense fallback={<LoadingSpinner />}>
            <Routes>
              <Route path="/" element={
                <>
                  <section id="hero"><Hero /></section>
                  <section id="skills"><Skills /></section>
                  <section id="works"><Works /></section>
                  <section id="about"><AboutMe /></section>           
                </>
              } />
            </Routes>
          </Suspense>
        </ContentWrapper>
        <Footer />
      </AppContainer>
    </Router>
  );
}

export default App;
