import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { motion, AnimatePresence } from 'framer-motion';
import { FaLinkedin, FaGithub, FaEnvelope, FaBars, FaTimes } from 'react-icons/fa';
import { colors } from '../styles/colors';

const Nav = styled(motion.nav)`
  width: 100%;
  padding: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: rgba(10, 31, 68, 0.3);
  position: fixed;
  top: 0;
  z-index: 1000;
  backdrop-filter: blur(10px);
`;

const Logo = styled(motion.h1)`
  color: ${colors.text};
  font-size: 24px;
  text-decoration: none;
  cursor: pointer;
`;

const NavLinks = styled.div`
  display: flex;

  @media (max-width: 768px) {
    display: none;
  }
`;

const MobileNav = styled(motion.div)`
  display: none;
  
  @media (max-width: 768px) {
    display: flex;
    flex-direction: column;
    position: fixed;
    top: 0;
    right: 0;
    height: 100vh;
    width: 75%;
    background-color: #112240;
    padding: 80px 40px;
    z-index: 999;
  }
`;

const NavLink = styled.a`
  color: white;
  text-decoration: none;
  margin-left: 20px;
  cursor: pointer;

  &:hover {
    color: ${colors.primary};
    transition: color 0.3s ease-in-out;
  }

  @media (max-width: 768px) {
    margin: 20px 0;
    font-size: 20px;
  }
`;

const ContactIcons = styled.div`
  display: flex;

  @media (max-width: 768px) {
    display: none;
  }
`;

const MobileContactIcons = styled.div`
  display: none;

  @media (max-width: 768px) {
    display: flex;
    gap: 20px;
    margin-top: 30px;
  }
`;

const IconWrapper = styled.a`
  font-size: 24px;
  color: white;
  margin-left: 15px;

  &:hover {
    color: ${colors.primary};
    transition: color 0.3s ease-in-out;
  }

  @media (max-width: 768px) {
    margin-left: 0;
  }
`;

const MenuButton = styled.button`
  display: none;
  background: none;
  border: none;
  color: white;
  font-size: 24px;
  cursor: pointer;
  z-index: 1001;

  @media (max-width: 768px) {
    display: block;
  }
`;

const Overlay = styled(motion.div)`
  display: none;
  
  @media (max-width: 768px) {
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.7);
    z-index: 998;
  }
`;

const Navbar = () => {
  const [prevScrollPos, setPrevScrollPos] = useState(0);
  const [visible, setVisible] = useState(true);
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollPos = window.pageYOffset;
      setVisible(prevScrollPos > currentScrollPos || currentScrollPos < 10);
      setPrevScrollPos(currentScrollPos);
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [prevScrollPos]);

  const scrollToSection = (sectionId) => {
    const section = document.querySelector(sectionId);
    if (section) {
      const navHeight = document.querySelector('nav').offsetHeight;
      const targetPosition = section.offsetTop - navHeight;
      
      window.scrollTo({
        top: targetPosition,
        behavior: 'smooth'
      });
      setIsOpen(false);
    }
  };

  const menuVariants = {
    open: {
      x: 0,
      transition: {
        type: "spring",
        stiffness: 100,
      }
    },
    closed: {
      x: "100%",
    }
  };

  const overlayVariants = {
    open: {
      opacity: 1,
      transition: {
        duration: 0.3
      }
    },
    closed: {
      opacity: 0,
      transition: {
        duration: 0.3
      }
    }
  };

  return (
    <AnimatePresence>
      {visible && (
        <Nav
          initial={{ y: -100 }}
          animate={{ y: 0 }}
          exit={{ y: -100 }}
          transition={{ duration: 0.3 }}
        >
          <motion.a 
            href="/"
            onClick={(e) => {
              e.preventDefault();
              window.scrollTo({ top: 0, behavior: 'smooth' });
            }}
          >
            <Logo
              initial={{ opacity: 1, scale: 1 }}
              whileHover={{ scale: 1.2 }}
              transition={{ duration: 0.3 }}
            >
              Khalid Ouhmid
            </Logo>
          </motion.a>

          <NavLinks>
            <NavLink href="#skills" onClick={(e) => {
              e.preventDefault();
              scrollToSection('#skills');
            }}>
              Skills
            </NavLink>
            <NavLink href="#works" onClick={(e) => {
              e.preventDefault();
              scrollToSection('#works');
            }}>
              Works
            </NavLink>
            <NavLink href="#about" onClick={(e) => {
              e.preventDefault();
              scrollToSection('#about');
            }}>
               About me
            </NavLink>
          </NavLinks>

          <ContactIcons>
            <IconWrapper 
              href="https://www.linkedin.com/in/khalid-ouhmid-062830329/" 
              target="_blank" 
              rel="noopener noreferrer"
            >
              <FaLinkedin />
            </IconWrapper>
            <IconWrapper 
              href="https://github.com/khalidOuhmid" 
              target="_blank" 
              rel="noopener noreferrer"
            >
              <FaGithub />
            </IconWrapper>
            <IconWrapper href="mailto:khalidouhmid.pro@gmail.com">
              <FaEnvelope />
            </IconWrapper>
          </ContactIcons>

          <MenuButton onClick={() => setIsOpen(!isOpen)}>
            {isOpen ? <FaTimes /> : <FaBars />}
          </MenuButton>

          <AnimatePresence>
            {isOpen && (
              <>
                <Overlay
                  initial="closed"
                  animate="open"
                  exit="closed"
                  variants={overlayVariants}
                  onClick={() => setIsOpen(false)}
                />
                <MobileNav
                  initial="closed"
                  animate="open"
                  exit="closed"
                  variants={menuVariants}
                >
                  <NavLink href="#about" onClick={(e) => {
                    e.preventDefault();
                    scrollToSection('#about');
                  }}>
                    About Me
                  </NavLink>
                  <NavLink href="#skills" onClick={(e) => {
                    e.preventDefault();
                    scrollToSection('#skills');
                  }}>
                    Skills
                  </NavLink>
                  <NavLink href="#works" onClick={(e) => {
                    e.preventDefault();
                    scrollToSection('#works');
                  }}>
                    Works
                  </NavLink>
                  <MobileContactIcons>
                    <IconWrapper 
                      href="https://www.linkedin.com/in/khalid-ouhmid-062830329/" 
                      target="_blank" 
                      rel="noopener noreferrer"
                    >
                      <FaLinkedin />
                    </IconWrapper>
                    <IconWrapper 
                      href="https://github.com/khalidOuhmid" 
                      target="_blank" 
                      rel="noopener noreferrer"
                    >
                      <FaGithub />
                    </IconWrapper>
                    <IconWrapper href="mailto:khalidouhmid.pro@gmail.com">
                      <FaEnvelope />
                    </IconWrapper>
                  </MobileContactIcons>
                </MobileNav>
              </>
            )}
          </AnimatePresence>
        </Nav>
      )}
    </AnimatePresence>
  );
};

export default Navbar;
