import { createGlobalStyle } from 'styled-components';

const GlobalStyles = createGlobalStyle`
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }

  body {
    display: flex;
    flex-direction: column;
    min-height: 100vh; /* Assure que le body prend toute la hauteur */
    background-color: #0a0a0a; /* Fond noir uniforme */
    color: #e0e0e0; /* Texte en gris clair */
    font-family: 'Arial', sans-serif;
    scroll-behavior: smooth;
  }

  #root {
    display: flex;
    flex-direction: column;
    min-height: 100vh; /* Assure que l'élément racine prend toute la hauteur */
  }
`;

export default GlobalStyles;
